import type {Dashboard} from '@nebius-ui/spd';

export const schedulerPoolDashboardConfig: Dashboard = {
    annotations: {
        list: [
            {
                builtIn: 1,
                datasource: {
                    type: 'grafana',
                    uid: '-- Grafana --',
                },
                enable: true,
                hide: true,
                iconColor: 'rgba(0, 211, 255, 1)',
                name: 'Annotations & Alerts',
                type: 'dashboard',
            },
        ],
    },
    editable: true,
    fiscalYearStartMonth: 0,
    graphTooltip: 0,
    id: 1879,
    links: [],
    panels: [
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 2,
                w: 24,
                x: 0,
                y: 0,
            },
            id: 1,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content: '<h2>Usage, demand and strong guarantee</h2>',
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: 'CPU, cores',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                                value: null,
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 2,
            },
            id: 2,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_resource_usage_cpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'usage',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_resource_demand_cpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'demand',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_effective_strong_guarantee_resources_cpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'strong_guarantee',
                    refId: 'C',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_specified_resource_limits_cpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'specified_limit',
                    refId: 'D',
                },
            ],
            title: 'CPU: usage, demand, guarantee',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: 'Memory, bytes',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                                value: null,
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 8,
                y: 2,
            },
            id: 3,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_resource_usage_user_memory", pool="$pool", tree="$tree"}',
                    legendFormat: 'usage',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_resource_demand_user_memory", pool="$pool", tree="$tree"}',
                    legendFormat: 'demand',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_effective_strong_guarantee_resources_user_memory", pool="$pool", tree="$tree"}',
                    legendFormat: 'strong_guarantee',
                    refId: 'C',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_specified_resource_limits_user_memory", pool="$pool", tree="$tree"}',
                    legendFormat: 'specified_limit',
                    refId: 'D',
                },
            ],
            title: 'RAM: usage, demand, guarantee',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: 'GPU, cards',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                                value: null,
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 16,
                y: 2,
            },
            id: 4,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_resource_usage_gpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'usage',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_resource_demand_gpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'demand',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_effective_strong_guarantee_resources_gpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'strong_guarantee',
                    refId: 'C',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_specified_resource_limits_gpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'specified_limit',
                    refId: 'D',
                },
            ],
            title: 'GPU: usage, demand, guarantee',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: 'Operation count',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                                value: null,
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 12,
                x: 0,
                y: 8,
            },
            id: 5,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_running_operation_count", pool="$pool", tree="$tree"}',
                    legendFormat: 'usage',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_lightweight_running_operation_count", pool="$pool", tree="$tree"}',
                    legendFormat: 'lightweight usage',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_max_running_operation_count", pool="$pool", tree="$tree"}',
                    legendFormat: 'limit',
                    refId: 'C',
                },
            ],
            title: 'Running operation count',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: 'Operation count',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                                value: null,
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 12,
                x: 12,
                y: 8,
            },
            id: 6,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_total_operation_count", pool="$pool", tree="$tree"}',
                    legendFormat: 'usage',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_max_operation_count", pool="$pool", tree="$tree"}',
                    legendFormat: 'limit',
                    refId: 'B',
                },
            ],
            title: 'Total operation count',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 14,
            },
            id: 7,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content:
                    '**Demand**: amount of resources needed to run all waiting and running jobs in the pool.<br>\n**Usage**: model resource consumption of the pool from scheduler\'s point of view. Represents the number of resources that are reserved for operations in the pool. Actual resource consumption may differ.<br>\n**Strong guarantee**: amount of resources that is guaranteed for operations in the pool at any moment.<br>\n**Running operation count**: number of operations in the pool which are running, i.e. are considered for job scheduling. Also displays the number of "lightweight" running operations, which are not accounted in the running operation count limit.<br>\n**Total operation count**: total number of operations in the pool, includes "Running operation count". The difference between "Total operation count" and "Running operation count" is the size of the queue of operations that are waiting to be started.',
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 2,
                w: 24,
                x: 0,
                y: 21,
            },
            id: 8,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content: '<h2>Cluster share</h2>',
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 23,
            },
            id: 9,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_dominant_usage_share", pool="$pool", tree="$tree"}',
                    legendFormat: 'dominant usage share',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_dominant_demand_share", pool="$pool", tree="$tree"}',
                    legendFormat: 'dominant demand share',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_dominant_fair_share_total", pool="$pool", tree="$tree"}',
                    legendFormat: 'dominant fair share',
                    refId: 'C',
                },
            ],
            title: 'Resource shares: usage, demand, fair share',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 8,
                y: 23,
            },
            id: 10,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_fair_share_total_cpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'CPU',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_fair_share_total_memory", pool="$pool", tree="$tree"}',
                    legendFormat: 'memory',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_fair_share_total_gpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'GPU',
                    refId: 'C',
                },
            ],
            title: 'Fair share: CPU, RAM, GPU components',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 100,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'normal',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 16,
                y: 23,
            },
            id: 11,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_dominant_fair_share_integral_guarantee", pool="$pool", tree="$tree"}',
                    legendFormat: 'integral guarantee',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_dominant_fair_share_strong_guarantee", pool="$pool", tree="$tree"}',
                    legendFormat: 'strong guarantee',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_dominant_fair_share_weight_proportional", pool="$pool", tree="$tree"}',
                    legendFormat: 'weight proportional',
                    refId: 'C',
                },
            ],
            title: 'Dominant fair share: strong, integral, weight proportional',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 5,
                w: 24,
                x: 0,
                y: 29,
            },
            id: 12,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content:
                    "**Usage**, **Demand**, **Fair share**: share of the cluster that is used, demanded or should be given to the pool. For example, 1.0 or 100% corresponds to the total amount of resources in the cluster.<br>\n**CPU. RAM, GPU fair share components**  help to determine pool's dominant resource.\n\nIn addition, fair share can be split into three parts: strong guarantee, integral guarantee and free resources, which are distributed between pools in proportion to their weights.",
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 2,
                w: 24,
                x: 0,
                y: 34,
            },
            id: 13,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content: '<h2>Job metrics</h2>',
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 36,
            },
            id: 14,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: 'rate({__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_metrics_user_job_io_total", pool="$pool", tree="$tree"}[$__rate_interval])',
                    legendFormat: 'user job io rate',
                    refId: 'A',
                },
            ],
            title: 'Job metrics: local disk IO',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 100,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'normal',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 8,
                y: 36,
            },
            id: 15,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: 'rate({__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_metrics_user_job_cpu_system", pool="$pool", tree="$tree"}[$__rate_interval])',
                    legendFormat: 'user job system cpu rate',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: 'rate({__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_metrics_user_job_cpu_user", pool="$pool", tree="$tree"}[$__rate_interval])',
                    legendFormat: 'user job user cpu rate',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: 'rate({__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_metrics_job_proxy_cpu_system", pool="$pool", tree="$tree"}[$__rate_interval])',
                    legendFormat: 'job proxy system cpu rate',
                    refId: 'C',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: 'rate({__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_metrics_job_proxy_cpu_user", pool="$pool", tree="$tree"}[$__rate_interval])',
                    legendFormat: 'job proxy user cpu rate',
                    refId: 'D',
                },
            ],
            title: 'Job metrics: CPU usage',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 16,
                y: 36,
            },
            id: 16,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: 'rate({__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_metrics_gpu_load", pool="$pool", tree="$tree"}[$__rate_interval]) / 1000.0',
                    legendFormat: 'gpu load rate',
                    refId: 'A',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: 'rate({__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_metrics_gpu_utilization_gpu", pool="$pool", tree="$tree"}[$__rate_interval]) / 1000.0',
                    legendFormat: 'gpu utilization rate',
                    refId: 'B',
                },
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_resource_usage_gpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'pools gpu usage',
                    refId: 'C',
                },
            ],
            title: 'Job metrics: GPU usage',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 4,
                w: 24,
                x: 0,
                y: 42,
            },
            id: 17,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content:
                    '**Local disk I/O**: total amount of input/output operations per second (IOPS) of all user jobs in the pool.<br>\n**CPU usage**: total detailed CPU usage statistics of all user jobs and their job proxies in the pool.<br>\n**GPU usage**: total detailed GPU utilization statistics of all user jobs in the pool.\n',
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 2,
                w: 24,
                x: 0,
                y: 46,
            },
            id: 18,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content: '<h2>Integral guarantee</h2>',
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 48,
            },
            id: 19,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_accumulated_resource_volume_cpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'accumulated CPU',
                    refId: 'A',
                },
            ],
            title: 'Accumulated resource volume: CPU',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 8,
                y: 48,
            },
            id: 20,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_accumulated_resource_volume_user_memory", pool="$pool", tree="$tree"}',
                    legendFormat: 'accumulated memory',
                    refId: 'A',
                },
            ],
            title: 'Accumulated resource volume: RAM',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 16,
                y: 48,
            },
            id: 21,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_accumulated_volume_dominant_share", pool="$pool", tree="$tree"}',
                    legendFormat: 'accumulated dominant resource share',
                    refId: 'A',
                },
            ],
            title: 'Accumulated resource volume share',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 4,
                y: 54,
            },
            id: 22,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_accepted_free_volume_cpu", pool="$pool", tree="$tree"}',
                    legendFormat: 'accepted free CPU',
                    refId: 'A',
                },
            ],
            title: 'Accepted free volume: CPU',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'victoriametrics-datasource',
                uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
            },
            fieldConfig: {
                defaults: {
                    color: {
                        mode: 'palette-classic',
                    },
                    custom: {
                        axisBorderShow: false,
                        axisCenteredZero: false,
                        axisColorMode: 'text',
                        axisLabel: '',
                        axisPlacement: 'auto',
                        barAlignment: 0,
                        drawStyle: 'line',
                        fillOpacity: 0,
                        gradientMode: 'none',
                        hideFrom: {
                            legend: false,
                            tooltip: false,
                            viz: false,
                        },
                        insertNulls: false,
                        lineInterpolation: 'linear',
                        lineWidth: 1,
                        pointSize: 5,
                        scaleDistribution: {
                            type: 'linear',
                        },
                        showPoints: 'auto',
                        spanNulls: false,
                        stacking: {
                            group: 'A',
                            mode: 'none',
                        },
                        thresholdsStyle: {
                            mode: 'off',
                        },
                    },
                    mappings: [],
                    min: 0,
                    thresholds: {
                        mode: 'absolute',
                        steps: [
                            {
                                color: 'green',
                            },
                            {
                                color: 'red',
                                value: 80,
                            },
                        ],
                    },
                    unit: 'short',
                },
                overrides: [],
            },
            gridPos: {
                h: 6,
                w: 8,
                x: 12,
                y: 54,
            },
            id: 23,
            options: {
                legend: {
                    calcs: [],
                    displayMode: 'list',
                    placement: 'bottom',
                    showLegend: true,
                },
                tooltip: {
                    mode: 'single',
                    sort: 'none',
                },
            },
            targets: [
                {
                    datasource: {
                        type: 'victoriametrics-datasource',
                        uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                    },
                    expr: '{__workspace__="yt", __bucket__="kyt", app_kubernetes_io_instance="$cluster", service="yt-scheduler-monitoring", __name__="yt_scheduler_pools_accepted_free_volume_user_memory", pool="$pool", tree="$tree"}',
                    legendFormat: 'accepted free memory',
                    refId: 'A',
                },
            ],
            title: 'Accepted free volume: RAM',
            type: 'timeseries',
        },
        {
            datasource: {
                type: 'alertmanager',
                uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
            },
            gridPos: {
                h: 6,
                w: 24,
                x: 0,
                y: 60,
            },
            id: 24,
            options: {
                code: {
                    language: 'plaintext',
                    showLineNumbers: false,
                    showMiniMap: false,
                },
                content:
                    "The following metrics are only relevant for pools with burst or relaxed integral guarantees.\n\n**Accumulated resource volume share**: amount of virtual resources accumulated in the pool which can be spent on integral guarantees. In `cluster_share*sec`.<br>\n**Accumulated resource volume (CPU/RAM)**: approximate amount of accumulated resources computed based on accumulated volume share and the current total resource amount in the cluster. In `cpu*sec`/`bytes*sec`.<br>\n**Accepted free volume (CPU/RAM)**: virtual resource volume which was obtained by the pool via redistribution of other pools' unused integral resource flows.\n",
                mode: 'markdown',
            },
            pluginVersion: '11.1.0',
            targets: [
                {
                    datasource: {
                        type: 'alertmanager',
                        uid: 'b4c159ed-d844-41ce-9371-3874430d8381',
                    },
                    refId: 'A',
                },
            ],
            type: 'text',
        },
    ],
    schemaVersion: 39,
    tags: [],
    templating: {
        list: [
            // @ts-ignore
            {
                current: {
                    selected: false,
                    text: 'cluster',
                    value: 'cluster',
                },
                label: 'cluster',
                name: 'cluster',
            },
            // @ts-ignore
            {
                current: {
                    selected: false,
                    text: '__rate_interval',
                    value: '__rate_interval',
                },
                label: '__rate_interval',
                hide: 0,
                includeAll: false,
                multi: false,
                name: '__rate_interval',
            },
            {
                current: {
                    selected: false,
                    text: '<Root>',
                    value: '<Root>',
                },
                datasource: {
                    type: 'victoriametrics-datasource',
                    uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                },
                definition:
                    'label_values({service=~"yt-scheduler-monitoring", __name__=~"yt_scheduler_pools_resource_usage_cpu", __bucket__="kyt", app_kubernetes_io_instance="$cluster", __workspace__="yt"}, pool)',
                hide: 0,
                includeAll: false,
                label: 'pool',
                multi: false,
                name: 'pool',
                options: [],
                query: {
                    query: 'label_values({service=~"yt-scheduler-monitoring", __name__=~"yt_scheduler_pools_resource_usage_cpu", __bucket__="kyt", app_kubernetes_io_instance="$cluster", __workspace__="yt"}, pool)',
                    refId: 'StandardVariableQuery',
                },
                refresh: 1,
                regex: '',
                skipUrlSync: false,
                sort: 0,
                type: 'query',
            },
            {
                current: {
                    selected: false,
                    text: 'default',
                    value: 'default',
                },
                datasource: {
                    type: 'victoriametrics-datasource',
                    uid: 'fc872d93-d988-491c-83cd-19249cb962b0',
                },
                definition:
                    'label_values({service=~"yt-scheduler-monitoring", __name__=~"yt_scheduler_pools_resource_usage_cpu", pool="$pool", __bucket__="kyt", app_kubernetes_io_instance="$cluster", __workspace__="yt"}, tree)',
                hide: 0,
                includeAll: false,
                label: 'tree',
                multi: false,
                name: 'tree',
                options: [],
                query: {
                    query: 'label_values({service=~"yt-scheduler-monitoring", __name__=~"yt_scheduler_pools_resource_usage_cpu", pool="$pool", __bucket__="kyt", app_kubernetes_io_instance="$cluster", __workspace__="yt"}, tree)',
                    refId: 'StandardVariableQuery',
                },
                refresh: 1,
                regex: '',
                skipUrlSync: false,
                sort: 0,
                type: 'query',
            },
        ],
    },
    time: {
        from: 'now-6h',
        to: 'now',
    },
    timepicker: {},
    timezone: '',
    title: 'Scheduler pool',
    uid: 'adxovml6hvn5sb',
    version: 4,
    weekStart: '',
};
