import React, {useCallback, useMemo} from 'react';
import block from 'bem-cn-lite';
import {Flex, Icon} from '@gravity-ui/uikit';
import {
    ArrowDown,
    ArrowsRotateRight,
    CirclePlay,
    CircleStop,
    FloppyDisk,
    LogoPython,
} from '@gravity-ui/icons';

import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {JupytListResponseItem} from 'features/Jupyter/api/jupyt';

import {JupytSelect} from '../JupytSelect/JupytSelect';
import PathActions from '@ytsaurus-ui-platform/src/ui/pages/navigation/content/MapNode/PathActions';

import './JupytOperationsList.scss';

const b = block('jupyt-operations-list');

type JupytOperationsListProps = {
    isNotebookSaved: boolean;
    isNotebookRunning: boolean;
    operations: JupytListResponseItem[];
    onRunNotebookClick: () => void;
    onStopNotebookClick: () => void;
    onSaveNotebookClick: () => void;
    onRestartKernelClick: () => void;
    currentJupytAlias: string;
    onCurrentJupytAliasChange: (value: string) => void;
    jupytOperationsLink: string;
    onEmptyJupytSelectClick: () => void;
    path: string;
    notebookDownloadUrl: string;
    attributes: Record<string, any>;
};

export const JupytOperationsList: React.FC<JupytOperationsListProps> = ({
    isNotebookSaved,
    isNotebookRunning,
    operations,
    onRunNotebookClick,
    onStopNotebookClick,
    onSaveNotebookClick,
    onRestartKernelClick,
    currentJupytAlias,
    onCurrentJupytAliasChange,
    jupytOperationsLink,
    onEmptyJupytSelectClick,
    path,
    notebookDownloadUrl,
    attributes,
}) => {
    const handleJupytAliasChange = useCallback(
        ([value]: string[]) => {
            onCurrentJupytAliasChange(value);
        },
        [onCurrentJupytAliasChange],
    );

    const notebookDownloadName = useMemo(() => {
        const filename = path.split('/').pop();

        if (filename?.endsWith('.ipynb')) {
            return filename;
        }

        return `${filename}.ipynb`;
    }, [path]);

    return (
        <Flex direction="row" gap={3} className={b()}>
            <JupytSelect
                jupyts={operations}
                onEmptyClick={onEmptyJupytSelectClick}
                onUpdate={handleJupytAliasChange}
                currentJupyt={[currentJupytAlias]}
            />
            <Flex gap={2}>
                <Button href={jupytOperationsLink} target="_blank">
                    <Icon data={LogoPython} size={13} />
                    To kernels
                </Button>
                {currentJupytAlias ? (
                    <Button onClick={onRestartKernelClick}>
                        <Icon data={ArrowsRotateRight} size={13} />
                        Restart kernel
                    </Button>
                ) : null}
                {isNotebookRunning ? (
                    <Button onClick={onRunNotebookClick}>
                        <Icon data={CirclePlay} size={13} />
                        Run all
                    </Button>
                ) : (
                    <Button onClick={onStopNotebookClick} selected>
                        <Icon data={CircleStop} size={13} />
                        Stop execution
                    </Button>
                )}
                <Button extraProps={{download: notebookDownloadName}} href={notebookDownloadUrl}>
                    <Icon data={ArrowDown} size={13} />
                    Download
                </Button>
                <Button
                    className={b('save-button')}
                    onClick={onSaveNotebookClick}
                    disabled={isNotebookSaved}
                    loading={!isNotebookSaved}
                >
                    <Icon data={FloppyDisk} size={13} />
                    {isNotebookSaved ? 'Saved' : 'Saving'}
                </Button>
                <PathActions
                    onlyDropdown={true}
                    dropDownBtnTheme={'outlined'}
                    dropDownBtnSize={'m'}
                    item={{
                        type: 'document',
                        path,
                        dynamic: false,
                        // @ts-ignore
                        // it is used only with table type and can be ignored
                        tabletState: undefined,
                        $attributes: attributes,
                        $value: undefined,
                    }}
                />
            </Flex>
        </Flex>
    );
};
