import {createSlice} from '@reduxjs/toolkit';
import {type ICell, type INotebookContent, IOutput, MultilineString} from '@jupyterlab/nbformat';
import * as nbformat from '@jupyterlab/nbformat';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {CellType} from '../../types';
import {extractCellId} from '../../utils/cell';
import {getItemStrict} from '../../utils/strict-selectors';
import type {IUpdateDisplayDataMsg} from '@jupyterlab/services/lib/kernel/messages';
import {generateNotebookCypressId} from '../../utils/notebook';
import {CheckPermissionResult} from '../../../../../platform/packages/ui/src/shared/utils/check-permission';

type NotebookState = {
    editableCellId: string;
    focusedCellId: string;
    runningCells: Record<string, boolean>;
    dirtyCells: Record<string, ICell>;
    content: nbformat.INotebookContent | undefined;
    savedContent: nbformat.INotebookContent | undefined;
    writePermission: CheckPermissionResult['action'];
};

const initialState: NotebookState = {
    editableCellId: '',
    focusedCellId: '',
    runningCells: {},
    dirtyCells: {},
    content: undefined,
    savedContent: undefined,
    writePermission: 'deny',
};

const shiftCell = (cells: ICell[], oldIndex: number, newIndex: number) => {
    const replacedCell = cells[newIndex];
    cells[newIndex] = cells[oldIndex];
    cells[oldIndex] = replacedCell;
};

const getCellById = (cells: ICell[], cellId: string) => {
    return cells.find((cell) => extractCellId(cell) === cellId);
};

const getCellIndex = (cells: ICell[], cellId: string) => {
    return cells.findIndex((cell) => extractCellId(cell) === cellId);
};

type CreateCodeCellPayload = {
    cell_id?: string;
    source?: MultilineString;
};

const createCodeCell = ({cell_id, source}: CreateCodeCellPayload = {}): nbformat.ICodeCell => ({
    cell_type: 'code',
    metadata: {
        cell_id: cell_id || crypto.randomUUID(),
    },
    outputs: [],
    execution_count: null,
    source: source || [],
});

type CreateMarkdownCellPayload = {
    cell_id?: string;
    source?: MultilineString;
};

const createMarkdownCell = ({
    cell_id,
    source,
}: CreateMarkdownCellPayload = {}): nbformat.IMarkdownCell => ({
    cell_type: 'markdown',
    metadata: {
        cell_id: cell_id || crypto.randomUUID(),
    },
    source: source || [],
});

export const notebookSlice = createSlice({
    name: 'jupyter.notebook',
    initialState,
    reducers: {
        moveCellUp: (state, action: PayloadAction<{currentIndex: number}>) => {
            const notebook = getItemStrict(state.content);

            const oldIndex = action.payload.currentIndex;
            const newIndex = Math.max(action.payload.currentIndex - 1, 0);

            shiftCell(notebook.cells, oldIndex, newIndex);
        },
        moveCellDown: (state, action: PayloadAction<{currentIndex: number}>) => {
            const notebook = getItemStrict(state.content);
            const newIndex = Math.min(action.payload.currentIndex + 1, notebook.cells.length - 1);

            shiftCell(notebook.cells, action.payload.currentIndex, newIndex);
        },
        changeCellPosition: (
            state,
            action: PayloadAction<{oldIndex: number; newIndex: number}>,
        ) => {
            const notebook = getItemStrict(state.content);
            shiftCell(notebook.cells, action.payload.oldIndex, action.payload.newIndex);
        },
        setCellSource: (
            state,
            action: PayloadAction<{cellId: string; source: MultilineString}>,
        ) => {
            const cellId = action.payload.cellId;
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, cellId);

            if (cell) {
                if (!state.dirtyCells[cellId] && cell.source && cell.execution_count) {
                    state.dirtyCells[cellId] = {...cell};
                } else if (
                    state.dirtyCells[cellId] &&
                    state.dirtyCells[cellId].source === action.payload.source
                ) {
                    delete state.dirtyCells[cellId];
                }

                cell.source = action.payload.source;
            }
        },
        setCellOutputs: (state, action: PayloadAction<{cellId: string; outputs: IOutput[]}>) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isCode(cell)) {
                cell.outputs = action.payload.outputs;
            }
        },
        addCellOutput: (state, action: PayloadAction<{cellId: string; output: IOutput}>) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isCode(cell)) {
                cell.outputs.push(action.payload.output);
            }
        },
        updateCellDisplayData: (state, action: PayloadAction<{msg: IUpdateDisplayDataMsg}>) => {
            const notebook = getItemStrict(state.content);

            notebook.cells.forEach((cell) => {
                if (nbformat.isCode(cell)) {
                    cell.outputs.forEach((output) => {
                        if (
                            (output.metadata as any)?.transient?.display_id ===
                            action.payload.msg.content.transient.display_id
                        ) {
                            output.data = action.payload.msg.content.data;
                        }
                    });
                }
            });
        },
        setCellExecuteCount: (
            state,
            action: PayloadAction<{cellId: string; execution_count: nbformat.ExecutionCount}>,
        ) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell) {
                cell.execution_count = action.payload.execution_count;
            }
        },
        addCellAfter: (
            state,
            action: PayloadAction<{currentIndex: number; type?: 'code' | 'markdown'}>,
        ) => {
            const notebook = getItemStrict(state.content);
            const cell = action.payload.type === 'code' ? createCodeCell() : createMarkdownCell();
            notebook.cells.splice(action.payload.currentIndex + 1, 0, cell);

            const cellId = extractCellId(cell);
            state.focusedCellId = cellId;
            state.editableCellId = cellId;
        },
        deleteCell: (state, action: PayloadAction<{currentIndex: number}>) => {
            const notebook = getItemStrict(state.content);
            notebook.cells = notebook.cells.filter(
                (_cell, index) => action.payload.currentIndex !== index,
            );
        },
        setNotebook: (
            state,
            action: PayloadAction<{
                notebook: INotebookContent;
                writePermission?: CheckPermissionResult['action'];
            }>,
        ) => {
            const notebookCypressId = action.payload.notebook.metadata.notebook_cypress_id
                ? action.payload.notebook.metadata.notebook_cypress_id
                : generateNotebookCypressId();

            state.content = {
                ...action.payload.notebook,
                metadata: {
                    notebook_cypress_id: notebookCypressId,
                    ...action.payload.notebook.metadata,
                },
            };

            state.savedContent = action.payload.notebook;

            if (action.payload.writePermission) {
                state.writePermission = action.payload.writePermission;
            }
        },
        updateSavedNotebookContent: (state) => {
            state.savedContent = state.content;
        },
        clearNotebookState: () => initialState,
        startCellExecution: (state, action: PayloadAction<{cellId: string}>) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell) {
                state.runningCells[action.payload.cellId] = true;
                cell.outputs = [];
            }
        },
        finishCellExecution: (state, action: PayloadAction<{cellId: string}>) => {
            delete state.runningCells[action.payload.cellId];
            delete state.dirtyCells[action.payload.cellId];
        },
        runAllCells(state) {
            const notebook = getItemStrict(state.content);
            state.runningCells = notebook.cells.reduce((acc: Record<string, boolean>, cell) => {
                if (nbformat.isCode(cell)) {
                    acc[extractCellId(cell)] = true;
                }
                return acc;
            }, {});
            state.dirtyCells = {};
        },
        interruptExecution: (state) => {
            state.runningCells = {};
        },
        setFocusedCellId: (state, action: PayloadAction<{cellId: string}>) => {
            state.focusedCellId = action.payload.cellId;
        },
        changeCellType: (state, action: PayloadAction<{type: CellType; cellId: string}>) => {
            const notebook = getItemStrict(state.content);
            const index = getCellIndex(notebook.cells, action.payload.cellId);
            const cell = notebook.cells[index];

            if (cell) {
                const cellId = extractCellId(cell);

                let newCell;
                switch (action.payload.type) {
                    case 'markdown': {
                        newCell = createMarkdownCell({
                            cell_id: cellId,
                            source: cell.source,
                        });
                        break;
                    }
                    case 'code': {
                        newCell = createCodeCell({
                            cell_id: cellId,
                            source: cell.source,
                        });
                        break;
                    }
                }

                if (newCell) {
                    notebook.cells[index] = newCell;
                }
            }
        },
        upFromCurrentCell: (state) => {
            const notebook = getItemStrict(state.content);
            const index = getCellIndex(notebook.cells, state.focusedCellId);

            if (index > 0) {
                state.focusedCellId = extractCellId(notebook.cells[index - 1]);
            }
        },
        downFromCurrentCell: (state) => {
            const notebook = getItemStrict(state.content);
            const index = getCellIndex(notebook.cells, state.focusedCellId);

            if (index !== -1 && index < notebook.cells.length - 1) {
                state.focusedCellId = extractCellId(notebook.cells[index + 1]);
            }
        },
        makeCellEditable(state) {
            state.editableCellId = state.focusedCellId;
        },
        removeCellEditable(state) {
            state.editableCellId = '';
        },
        setCellAttachment(
            state,
            action: PayloadAction<{cellId: string; name: string; type: string; base64: string}>,
        ) {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isMarkdown(cell)) {
                cell.attachments = cell.attachments || {};
                cell.attachments[action.payload.name] = {
                    [action.payload.type]: action.payload.base64,
                };
            }
        },
    },
});
