import {IOutput, isCode} from '@jupyterlab/nbformat';
import {createSelector} from 'reselect';
import {NebiusRootState} from '../../../../store/reducers';

export const selectNotebookContent = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.content;

export const selectNotebookCells = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.content?.cells || [];

export const selectNotebookCellIds = createSelector(
    (state: NebiusRootState) => state.nebius.jupyter.notebook.content?.cells,
    (cells) => (cells || []).map((cell) => cell.metadata.cell_id) as string[],
);

export const selectCell = (state: NebiusRootState, cellId: string) => {
    return state.nebius.jupyter.notebook.content?.cells.find(
        (cell) => cell.metadata.cell_id === cellId,
    );
};

export const selectCellOutputs = (state: NebiusRootState, cellId: string) => {
    const cell = selectCell(state, cellId);

    let outputs: IOutput[] = [];

    if (cell && isCode(cell)) {
        outputs = cell.outputs;
    } else {
        outputs = [];
    }

    return outputs;
};

export const selectRunningCells = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.runningCells;

export const selectFocusedCellId = (state: NebiusRootState) => {
    return state.nebius.jupyter.notebook.focusedCellId;
};

export const selectFocusedCell = (state: NebiusRootState) => {
    const focusedCellId = state.nebius.jupyter.notebook.focusedCellId;

    return state.nebius.jupyter.notebook.content?.cells.find((cell) => {
        return cell.metadata.cell_id === focusedCellId;
    });
};

export const selectEditableCell = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.editableCellId;

export const selectCurrentJupytAlias = (state: NebiusRootState) =>
    state.nebius.jupyter.jupyt.currentJupytAlias;

export const selectIsNotebookSaved = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.content === state.nebius.jupyter.notebook.savedContent ||
    state.nebius.jupyter.notebook.writePermission === 'deny';

export const selectIsUserHasWritePermission = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.writePermission === 'allow';

export const getNotebookCypressId = (state: NebiusRootState) => {
    const notebookCypressId = state.nebius.jupyter.notebook?.content?.metadata?.notebook_cypress_id;

    if (notebookCypressId) {
        return String(notebookCypressId);
    }

    return null;
};

export const selectDirtyCells = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.dirtyCells;
