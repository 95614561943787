import * as React from 'react';
import {useCallback} from 'react';
import * as nbformat from '@jupyterlab/nbformat';
import {useSelector} from 'react-redux';
import {
    selectDirtyCells,
    selectEditableCell,
    selectFocusedCellId,
    selectNotebookCells,
    selectRunningCells,
} from '../../store/selectors/notebook';
import {JupyterCell} from '../../components/JupyterCell/JupyterCell';
import {
    executeCellByClick,
    executeFocusedCell,
    interruptExecution,
} from '../../store/actions/execute';
import {notebookSlice} from '../../store/slices/notebook';
import {extractCellId} from 'features/Jupyter/utils/cell';
import {NebiusRootState} from '../../../../store/reducers';
import {CellType, KeyEnum} from '../../types';
import {selectJupytOperations} from '../../store/selectors/jupyt';
import {type NebiusThunkDispatch, useNebiusDispatch} from '../../../../store/nebius-dispatch';

interface JupyterCellContainerProps {
    cellIndex: number;
}

const withFocusBeforeCellAction = (
    dispatch: NebiusThunkDispatch,
    cellId: string,
    cb: (...args: any) => void,
) => {
    return (...args: any) => {
        dispatch(notebookSlice.actions.setFocusedCellId({cellId}));

        return cb(...args);
    };
};

const useJupyterCell = (cellIndex: number) => {
    const dispatch = useNebiusDispatch();

    const operations = useSelector(selectJupytOperations);

    const cell = useSelector<NebiusRootState, nbformat.ICell>(
        (state) => selectNotebookCells(state)[cellIndex],
    );

    const cellId = extractCellId(cell);

    const isMarkdownCell = nbformat.isMarkdown(cell);

    const isRunning = useSelector<NebiusRootState, boolean>(
        (state) => selectRunningCells(state)[cellId],
    );

    const isFocused = useSelector<NebiusRootState, boolean>(
        (state) => selectFocusedCellId(state) === cellId,
    );

    const isEditable = useSelector<NebiusRootState, boolean>(
        (state) => selectEditableCell(state) === cellId,
    );

    const isDirty = useSelector<NebiusRootState, boolean>((state) =>
        Boolean(selectDirtyCells(state)[cellId]),
    );

    const onRunClick = useCallback(
        withFocusBeforeCellAction(dispatch, extractCellId(cell), () => {
            if (nbformat.isCode(cell)) {
                dispatch(executeCellByClick({cell}));
            }

            return undefined;
        }),
        [cell, operations],
    );

    const onCancelClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(interruptExecution());
        }),
        [cellId],
    );

    const onChange = useCallback(
        (source: string) => {
            dispatch(notebookSlice.actions.setCellSource({cellId, source}));
        },
        [cellId],
    );

    const onTrashBinClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(notebookSlice.actions.deleteCell({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId],
    );

    const onArrowUpClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(notebookSlice.actions.moveCellUp({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId],
    );

    const onArrowDownClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(notebookSlice.actions.moveCellDown({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId],
    );

    const onKeyDown = useCallback(
        (node: HTMLDivElement | null) => (event: React.KeyboardEvent<HTMLElement>) => {
            const anyMetaKey = event.shiftKey || event.ctrlKey || event.metaKey;

            if (!isEditable && !anyMetaKey && event.key === KeyEnum.ENTER) {
                event.preventDefault();
                dispatch(notebookSlice.actions.makeCellEditable());
            }

            if (event.key === KeyEnum.ESC) {
                node?.focus();
                dispatch(notebookSlice.actions.removeCellEditable());
                return;
            }

            if (anyMetaKey && event.key === KeyEnum.ENTER) {
                event.preventDefault();

                dispatch(executeFocusedCell());
            }
        },
        [isEditable],
    );

    const onEditorFocus = useCallback(
        (event: React.FocusEvent<HTMLElement>) => {
            if (!isEditable) {
                event.preventDefault();
                event.stopPropagation();

                if (!isFocused) {
                    dispatch(notebookSlice.actions.setFocusedCellId({cellId}));
                }

                if (!isMarkdownCell) {
                    dispatch(notebookSlice.actions.makeCellEditable());
                }
            }
        },
        [cellId, isEditable, isFocused, isMarkdownCell],
    );

    const onDoubleClick = useCallback(() => {
        if (isMarkdownCell) {
            dispatch(notebookSlice.actions.makeCellEditable());
        }
    }, [cellId, isMarkdownCell]);

    const onClick = useCallback(() => {
        if (!isFocused) {
            dispatch(notebookSlice.actions.setFocusedCellId({cellId}));
        }
    }, [isMarkdownCell, isFocused, cellId]);

    const onMarkdownBlur = useCallback(() => {
        dispatch(notebookSlice.actions.removeCellEditable());
    }, [cellId]);

    const onChangeCellType = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, (options: string[]) => {
            const [type] = options as CellType[];

            dispatch(
                notebookSlice.actions.changeCellType({
                    cellId,
                    type,
                }),
            );
        }),
        [cellId],
    );

    const onImagePaste = useCallback(
        ({name, base64, type}: {name: string; base64: string; type: string}) => {
            dispatch(
                notebookSlice.actions.setCellAttachment({
                    cellId,
                    name,
                    base64,
                    type,
                }),
            );
        },
        [cellId],
    );

    return {
        cell,
        isEditable,
        isRunning,
        isFocused,
        isDirty,
        onChange,
        onRunClick,
        onCancelClick,
        onTrashBinClick,
        onArrowUpClick,
        onArrowDownClick,
        onKeyDown,
        onEditorFocus,
        onDoubleClick,
        onMarkdownBlur,
        onChangeCellType,
        onImagePaste,
        onClick,
    };
};

export const JupyterCellContainer: React.FC<JupyterCellContainerProps> = (props) => {
    const useJupyterCellProps = useJupyterCell(props.cellIndex);

    return <JupyterCell {...useJupyterCellProps} />;
};
