import {getPool, getTree} from '@ytsaurus-ui-platform/src/ui/store/selectors/scheduling/scheduling';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {SchedulerPoolDashboardConfigVariables} from '../../configs/SchedulerPoolDashboardConfig';
import {ServiceDashboard} from '../../components/ServiceDashboard/ServiceDashboard';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {schedulerPoolDashboardConfig} from '../../../../../shared/constants/monitoring/scheduler-pool-dashboard-config';
import {getServiceDashboardConfig} from '../../utils/config';

type SchedulerPoolDashboardContainerProps = {};

export const SchedulerPoolDashboardContainer: React.FC<
    SchedulerPoolDashboardContainerProps
> = () => {
    const pool = useSelector(getPool);
    const tree = useSelector(getTree);
    const cluster = useSelector(getCluster);

    const config = useMemo(() => {
        const variables: SchedulerPoolDashboardConfigVariables = {
            pool: [pool],
            __rate_interval: ['30s'],
            cluster: [cluster],
            tree: [tree],
        };

        return getServiceDashboardConfig(schedulerPoolDashboardConfig, variables);
    }, [pool, cluster, tree]);

    return (
        <ServiceDashboard
            key={config.key}
            dashboard={config.dashboard}
            variables={config.variables}
        />
    );
};
